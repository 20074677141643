import i18n from "@/plugins/i18n";

export const _SHIFTS = {
  1: "F",
  2: "M",
  3: "S",
  4: "N",
};

export const SHIFT_LEVELS = [
  { key: 1, value: "f", name: "F" },
  { key: 2, value: "m", name: "M" },
  { key: 3, value: "s", name: "S" },
  { key: 4, value: "n", name: "N" },
];

export const SHIFT_STATUS = [
  { key: 1, label: i18n.t("status.open"), color: "orange" },
  { key: 2, label: i18n.t("status.assigned"), color: "green" },
  { key: 3, label: i18n.t("status.archived"), color: "blue" },
  { key: 4, label: i18n.t("status.deactivated"), color: "red" },
];
